import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators'
import { SixArtsCoursesService } from '../six-arts/six-arts-courses.service'

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;
  imagePath: string;

  constructor(private storage: AngularFireStorage,
              private service: SixArtsCoursesService) { }

  uploadFile(file, data) {
    // const file = event.target.files[0];
    // console.log(file.name);
    if (file!=null){

      const filePath = 'images/six_arts/' + data.id + ' ' + data.name;
      const fileRef = this.storage.ref(filePath);
      const task = this.storage.upload(filePath, file);

      this.uploadPercent = task.percentageChanges();
      // console.log(this.uploadPercent)
      // get notified when the download URL is available
      task.snapshotChanges().pipe(
          finalize(() =>
          // console.log("Processing");
          fileRef.getDownloadURL().subscribe(url => {
            // this.downloadURL = url;
            data.imageUrl = url;
            this.service.addCourse(data);

            // return this.downloadURL;
            // console.log(url);
            // return url;
          }) )
      )
      .subscribe()
    }
    else{
      this.service.addCourse(data);
      // console.log("no File")
    }



  }

}
