import { Injectable } from '@angular/core';
import { of as observableOf, BehaviorSubject } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { auth } from 'firebase/app';
import { Router } from '@angular/router';

import { NgForm } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private eventAuthError = new BehaviorSubject<string>("");
  eventAuthError$ = this.eventAuthError.asObservable();
  newUser: any;


  uid = this.afAuth.authState.pipe(
    map(authState => {
      if(!authState) {
        return null;
      }
      else {
        authState.uid;
      }
    }),
);
  isAdmin = observableOf(true);

  constructor(
    private router: Router,
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore) { }

  getUserState() {
    return this.afAuth.authState;
  }

  createUser(user) {
    this.afAuth.auth.createUserWithEmailAndPassword( user.email, user.password)
      .then( userCredential => {
        this.newUser = user;

        console.log(userCredential);

        userCredential.user.updateProfile( {
          displayName: user.firstName + ' ' + user.lastName
        });

        this.insertUserData(userCredential)
          .then(() => {
            this.router.navigate(['/home']);
          })

      })
      .catch(error => {
        console.log(error);
        this.eventAuthError.next(error);
      })

  }

  login( email: string, password: string){
    this.afAuth.auth.signInWithEmailAndPassword(email, password)
      .catch(error => {
        this.eventAuthError.next(error);
      })
      .then(userCredential => {
        this.router.navigate(['/six-arts']);
      })

    // this.afAuth.auth.signInWithPopup(new auth.GoogleAuthProvider());
  }

  logout(){
    this.afAuth.auth.signOut();
  }

  insertUserData( userCredential: firebase.auth.UserCredential){
    return this.afs.doc(`users/${userCredential.user.uid}`).set({
      email: this.newUser.email,
      firstname: this.newUser.firstName,
      lastname: this.newUser.lastName,
      role: 'network user'
    })

  }
}
