import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { switchMap, mergeMap } from 'rxjs/operators';

import { SeoService } from '../../seo.service';
import { SixArtsCoursesService, Record, SixArtsCourse } from './../six-arts-courses.service';
import { NgForm } from '@angular/forms';


@Component({
  selector: 'app-six-arts-management',
  templateUrl: './six-arts-management.component.html',
  styleUrls: ['./six-arts-management.component.css']
})
export class SixArtsManagementComponent implements OnInit {
  // private recordCollection: AngularFirestoreCollection<any>;
  record$: Observable<any[]>;
  record: any;
  course$: Observable<any[]>;
  weekdays = ["MON", "TUE", "WED", "THUR", "FRI"];
  colors = ["#ffefd6", "#c0d6f9", "#ffffdb", "#ffdbfb", "#e4f9d4"];
  records: Record[];
  courses: SixArtsCourse[];
  count: 0;

  constructor(
    public courseService: SixArtsCoursesService,
    private afs: AngularFirestore
    ) {
      // this.getCourse();

    }


  getCourse = () => {
    this.courseService.getAllRecords().subscribe(
      data => {
        this.record = data;
        console.log(this.record);

        // console.log('here')
        // console.log(this.courses);
      }
    )
  }


  ngOnInit() {
    // this.resetForm();
    // this.record$ = this.courseService.getAllRecords();

    this.record$ = this.courseService.getLatestRecord();

    this.course$ = this.courseService
      .getLatestRecord()
      .pipe(switchMap(r => this.courseService.getAllCourses(r[0].id)));


    // this.courseService.getRecords().subscribe(action => {
    //   this.courses = action.map(item => {
    //     return {
    //       id: item.payload.doc.id,
    //       ...item.payload.doc.data() } as SixArtsCourse;
    //   })
    // });

    // const apr19 = this.afs.collection('six-arts').doc('APR2019').collection('courses').doc('1085');


    // this.record$ = this.courseService.getAllRecords();
    // this.getCourse;
    // console.log(this.courses);



    // this.courseService.getRecords();
    // this.courseService.getRecords().subscribe(records => {
    //   this.records = records; })

    // console.log(this.courses);
    // console.log(this.record$);

    //   this.count += 1;
    // this.course$ = this.courseService
    //   .getAllRecords()
    //   .pipe(switchMap(r => this.courseService.getAllCourses(r[1].id)));


    // this.course$.subscribe(r => {this.courses = r});
    // console.log(this.courses);

  }

  // resetForm(form?: NgForm){
  //   if(form!= null)
  //     form.resetForm();
  //   this.courseService.formData = {
  //     id : null,
  //     name : "",
  //     day: "",
  //     time: "",
  //     imageUrl: null,
  //     // description: "",
  //     // teacher: "",
  //     // target: "",
  //     // dayt: "",
  //     // timet: ""

  //   }


}
