import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SeoService } from 'src/app/seo.service';

import { Cert, CertRecordService } from '../cert-record.service';

@Component({
  selector: 'app-training-record',
  templateUrl: './training-record.component.html',
  styleUrls: ['./training-record.component.css']
})
export class TrainingRecordComponent implements OnInit {

  record$: Observable<Cert[]>;

  constructor(
    private recordService: CertRecordService,
    private seo: SeoService,
  ) { }

  ngOnInit() {
    this.record$ = this.recordService.getAllRecords();
    this.seo.generateTags({
      title: '智保康 - 培訓記錄',
      description: '智保康 - 培訓記錄',
      // tslint:disable-next-line:max-line-length
      image: 'https://firebasestorage.googleapis.com/v0/b/se-ngo.appspot.com/o/images%2Ftraining.jpg?alt=media&token=0d96483d-e5db-4e33-a43e-75207a18f122',
      slug: 'training/training-record'
    });
  }

}
