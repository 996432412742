import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { SeoService } from 'src/app/seo.service';

import { Cert, CertRecordService } from '../cert-record.service';

@Component({
  selector: 'app-training-record-detail',
  templateUrl: './training-record-detail.component.html',
  styleUrls: ['./training-record-detail.component.css']
})
export class TrainingRecordDetailComponent implements OnInit, OnDestroy {

  certId: string;
  record$: Observable<Cert>;
  record: Cert;
  subscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private recordService: CertRecordService,
    private seo: SeoService,
  ) { }

  ngOnInit() {
    this.certId = this.route.snapshot.paramMap.get('certId');

    this.subscription = this.recordService.get(this.certId).subscribe( record => {
      this.record = record;
    });

    this.seo.generateTags({
      title: '智保康 - ' + this.certId,
      description: '智保康 - ' + this.certId,
      // tslint:disable-next-line:max-line-length
      image: 'https://firebasestorage.googleapis.com/v0/b/se-ngo.appspot.com/o/images%2Ftraining.jpg?alt=media&token=0d96483d-e5db-4e33-a43e-75207a18f122',
      slug: 'training/' + this.certId
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  isObject(val) {
    if (typeof val === 'object') {
      return true;
    }
    return false;
  }

}
