import { Component, OnInit } from '@angular/core';
import { LoginService } from '../login.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {

  authError: any;

  constructor(private auth: LoginService) { }

  ngOnInit() {
    this.authError.eventAuthErrors.subscribe( data => {
      this.authError = data;;
    })
  }

  // createUser(form) {
  //   this.auth.createUser(form.value);

  // }

  login(form) {
    this.auth.login(form.value.email, form.value.password);

  }

}
