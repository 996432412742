import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-training-guidance-doc',
  templateUrl: './training-guidance-doc.component.html',
  styleUrls: ['./training-guidance-doc.component.css']
})
export class TrainingGuidanceDocComponent implements OnInit {

  // tslint:disable: max-line-length
  guidanceDocs = [
    { name: 'Staff Handbook', url: 'https://firebasestorage.googleapis.com/v0/b/se-ngo.appspot.com/o/images%2Fpdf%2FStaff%20Handbook.pdf?alt=media&token=c84e1859-c706-4c52-9722-545c4dff79ab' },
    { name: 'Student Handbook', url: 'https://firebasestorage.googleapis.com/v0/b/se-ngo.appspot.com/o/images%2Fpdf%2FStudent%20Handbook.pdf?alt=media&token=53e345c3-ab23-41a2-8f06-511504f2f915'},
    { name: 'Application Form', url: 'https://firebasestorage.googleapis.com/v0/b/se-ngo.appspot.com/o/images%2Fpdf%2Fapplication%20form.pdf?alt=media&token=9567c2c5-b87d-4d6f-bbc3-e0dfc05eb94f'},
    { name: 'Data Privacy Policy', url: 'https://firebasestorage.googleapis.com/v0/b/se-ngo.appspot.com/o/images%2Fpdf%2FData%20Privacy%20Policy%20Statement.pdf?alt=media&token=49652631-0027-4ed9-ab7b-b77b4067ad1b'},
    { name: 'Quality Assurance', url: 'https://firebasestorage.googleapis.com/v0/b/se-ngo.appspot.com/o/images%2Fpdf%2FQuality%20Assurance.pdf?alt=media&token=e06ad8b9-6f30-40bd-aef7-dbe233835a8a'}
  ];
  // tslint:enable: max-line-length

  constructor() { }

  ngOnInit() {
  }

}
