import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

export interface SIProject {
  id?: string;
  project: string;
  type: string;
  year: number;
}

@Injectable({
  providedIn: 'root'
})
export class SiPastConsultancyService {

  constructor(
    private afs: AngularFirestore,
  ) { }

  getAllRecords() {
    return this.afs.collection<SIProject>('SI_past_projects', ref => ref.orderBy('year', 'desc'))
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const id = a.payload.doc.id;
            const data = a.payload.doc.data();
            return {id, ...data};
          });
        })
      );
  }
}
