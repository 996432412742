import { Component, OnInit } from '@angular/core';
import { SidebarService } from '../sidebar.service';

@Component({
  selector: 'app-volunteer',
  templateUrl: './volunteer.component.html',
  styleUrls: ['./volunteer.component.css']
})
export class VolunteerComponent implements OnInit {

  constructor(
      private sidebar: SidebarService
  ) { }

  ngOnInit() {
    // this.sidebar.closeSidebar();
  }

}
