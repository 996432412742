import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as firebase from 'firebase';

export interface Record {
  id: string; date_upload: string;
}


// export class SixArtsCourse {
//   name: string;
//   color?: string;
//   day?: string;
//   time?: string;
//   id?: string;
//   imageUrl?: string;
//   內容?: string;
//   學費?: string;
//   導師?: string;
//   日期?: string;
//   時間?: string;
// }

export class SixArtsCourse {
  name: string;
  color?: string;
  day?: string;
  times: string;
  timee: string;
  id?: string;
  imageUrl?: string;
  description?: string;
  tuition?: string;
  teacher?: string;
  dayt?: string;
  timet?: string;
}

export interface inputForm {
  name: string;
  color?: string;
  day?: string;
  times: string;
  timee: string;
  id?: string;
  imageUrl?: string;
  description?: string;
  tuition?: string;
  teacher?: string;
  dayt?: string;
  timet?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SixArtsCoursesService {

  formData: inputForm;
  timestamp = firebase.firestore.FieldValue.serverTimestamp();

  count = 0;
  recordCollection: AngularFirestoreCollection<Record>;
  records: Observable<Record[]>;


  constructor(
    private afs: AngularFirestore) {
    // this.records = this.afs.collection<Record>('six-arts')
    // .snapshotChanges()
    // .pipe(
    //   map(changes => {
    //     return changes.map(a => {
    //       const data = a.payload.doc.data() as Record;
    //       data.id = a.payload.doc.id;
    //     })}
    //     ));



  }

  getAllRecords() {
    console.log("enter")
    return this.afs.collection<Record>('six_arts')
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const id = a.payload.doc.id;
            const data = a.payload.doc.data();
            // console.log('all')
            // console.log(a.payload.doc);
            return {id, ...data};
          });
        })
      );
  }

  getRecords() {
    return this.afs.collection('six-arts/APR2019/courses').snapshotChanges();
    // return this.records;
  }

  getLatestRecord() {
    const latestRecord = this.afs.collection<Record>('six_arts', ref => ref.where('active', '==', true).orderBy('date_upload', 'desc').limit(1));
    return latestRecord
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const id = a.payload.doc.id;
            const data = a.payload.doc.data();
            // console.log('data');
            // console.log(data.last_update.toDate());
            // this.timestamp = data.last_update.toDate()
            return {id, ...data};
          });
        })
      );
  }


  getAllCourses(courseGroupId: string) {
    const courseCollection = this.afs.collection('six_arts')
      .doc(courseGroupId)
      .collection<SixArtsCourse>('courses', ref => ref.orderBy('times'));
    return courseCollection
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            this.count += 1;
            // console.log(this.count);

            const id = a.payload.doc.id;
            const data = a.payload.doc.data();
            return {id, ...data};
          });
        })
      );
  }


  getCourse(courseGroupId: string, courseId: string) {
    const ref = this.afs.collection('six_arts').doc(courseGroupId).collection('courses').doc<SixArtsCourse>(courseId);
    return ref.valueChanges();
  }

  addCourse(data: SixArtsCourse) {
    console.log(data);
    return this.afs.collection('six_arts').doc('APR2020').collection('courses').doc(data.id).set(data, {merge:true});
  }

  deleteCourse(id){
    if(confirm("Are you sure you want to delete "+id)) {
      return this.afs.collection('six_arts').doc('APR2020').collection('courses').doc(id).delete();
    }
  }


  updateTime() {
    // this.timestamp.toDate
    // const ref = this.afs.collection('six_arts').doc('APR2020')

    // var updateTimestamp = ref.update({
    //   last_update: firebase.firestore.FieldValue.serverTimestamp()
    // })

  }

  getDate() {

    // console.log(ref.valueChanges());
    console.log(this.timestamp);
    return this.timestamp;

  }




}
