export interface TrainingCourse {
  name: string;
  link: string;
}

export const SYLLABUS = {

  nursing: {name:"輕鬆護老法課程", link:"https://drive.google.com/file/d/1FH-M9XNynQNLfLgpWuRLunFgVakIxD2I/preview"},
  drugManagement: {name:"社署藥物管理秘笈", link:"https://drive.google.com/file/d/1RPEkNs61Q98GiOs1YpWzfaZzZsQgWEPH/preview"},
  drugInfo: {name:"社署藥物必讀", link: "https://drive.google.com/file/d/1W-ftHfT3B_I0MWcj19JXDvqc0QytjmDa/preview"}


};
