import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SidebarService } from './sidebar.service';
import { LoginService } from './login.service';

// declare ga as a function to set and sent the events
// tslint:disable-next-line:ban-types
declare let ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  // opened = true;
  title = 'se-website';

  constructor(public router: Router,
    public sidebar: SidebarService,
    public user: LoginService) {
    // subscribe to router events and send page views to Google Analytics
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }
      window.scrollTo(0,0);
    });
  }

  ngOnInit() {
    // this.sidebar.openSidebar();
  }

}
