import { Component, OnInit } from '@angular/core';

import { SeoService } from '../seo.service';
import { SidebarService } from '../sidebar.service';

@Component({
  selector: 'app-smart-int',
  templateUrl: './smart-int.component.html',
  styleUrls: ['./smart-int.component.css']
})
export class SmartIntComponent implements OnInit {

  page: number = 1;
  tl: boolean = false;

  constructor(
    private seo: SeoService,
    public sidebar: SidebarService
  ) { }

  ngOnInit() {
    // this.sidebar.closeSidebar();
    this.seo.generateTags({
      title: "Smart Integration Company",
      description: "Smart Integration Company",
      image: "https://se-ngo.org/assets/images/SI-logo.png",
      slug: "smart"
    });
  }

  setPage(num) {
    this.page = num;
  }

}
