import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { AngularFireStorage } from '@angular/fire/storage';

import { SixArtsCoursesService,SixArtsCourse } from '../../six-arts-courses.service';
import { UploadService } from '../../upload.service';

@Component({
  selector: 'app-six-arts-course-edit',
  templateUrl: './six-arts-course-edit.component.html',
  styleUrls: ['./six-arts-course-edit.component.css']
})
export class SixArtsCourseEditComponent implements OnInit {

  courseGroup: string;
  courseId: string;
  course$: Observable<any>;
  course: any;
  subscription: Subscription;

  // color = "#2889e9";
  column_names = ["id","日期","時間","內容","導師","學費"]
  column_en = ["id","dayt","timet","description","teacher","tuition"]
  imageSrc : string = "";
  selectedImage: any = null;
  isSubmitted = false;
  imageUrl : string;

  constructor(
    private route: ActivatedRoute,
    public courseService: SixArtsCoursesService,
    private storage: AngularFireStorage,
    public upload: UploadService,
    private router: Router,
    // private seo: SeoService,
  ) { }

  ngOnInit() {
    this.courseGroup = this.route.snapshot.paramMap.get('courseGroupId');
    this.courseId = this.route.snapshot.paramMap.get('courseId');
    this.course$ = this.courseService.getCourse(this.courseGroup, this.courseId);
    this.subscription = this.course$.subscribe(c => {
      this.course = c;

      console.log(this.course);
    });
    this.resetForm();

  }


  onSubmit(form: NgForm, image?){
    this.isSubmitted = true;


    let pre = form.value;


    // pre.color = this.color;
    // pre.time = pre.time_start + " - " + pre.time_end;
    // pre.imagePath=this.upload.imagePath;
    // delete pre.time_start;
    // delete pre.time_end;

    console.log(pre);

    // // pre.color = parseInt(pre.color);
    pre.column_names = this.column_names;
    pre.column_en = this.column_en;
    if (image) {

      pre.imageUrl = this.imageUrl;
    }
    else {
      delete pre.imageUrl;
    }
    let data = pre as SixArtsCourse;
    // console.log(data);

    this.upload.uploadFile(this.selectedImage, data);

    this.router.navigate(['/six-arts']);

    // this.resetForm(form);


  }

  initForm(c) {
    let data = c.value;
    data.times = data.time.substring(0,5);
    data.timee = data.time.substring(8,13);
    delete data.time;

    console.log("HERE");

    console.log(data);

  }


  ngOnDestroy() {
    // this.subscription.unsubscribe();
  }

  showPreview(event:any) {
    if(event.target.files && event.target.files[0]){
      const reader = new FileReader();
      reader.onload = (e:any) => this.imageSrc = e.target.result;
      reader.readAsDataURL(event.target.files[0]);
      this.selectedImage = event.target.files[0];
    }
    else {
      this.imageSrc = '';
      this.selectedImage = null;
    }


  }

  resetForm(form?: NgForm){
    if(form!= null)
      form.resetForm();
    this.courseService.formData = {
      id : null,
      name : "",
      color:"#ff00ff",
      day: "MON",
      times: "14:30",
      timee: "16:00",
      imageUrl: "",
      description: "",
      teacher: "",
      tuition: "",
      dayt: "",
      timet: ""
    }
    this.imageSrc = "";
    this.selectedImage = null;
    this.isSubmitted = false;
  }


}
