import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { SidebarService } from '../sidebar.service';

import { SeoService } from '../seo.service';
import { SixArtsCoursesService } from './six-arts-courses.service';
import { LoginService } from '../login.service';
import { Router } from '@angular/router';
import { AngularFirestoreCollection } from '@angular/fire/firestore';

@Component({
  selector: "app-six-arts",
  templateUrl: "./six-arts.component.html",
  styleUrls: ["./six-arts.component.css"]
})
export class SixArtsComponent implements OnInit {
  record$: Observable<any[]>;
  record: any;
  course$: Observable<any[]>;
  weekdays = ["MON", "TUE", "WED", "THUR", "FRI"];
  weekdayscn = ["星期一",	"星期二",	"星期三",	"星期四", "星期五"]
  colors = ["#ffefd6", "#c0d6f9", "#ffffdb", "#ffdbfb", "#e4f9d4"];

  manage: boolean = false;
  delete: boolean = false;
  ref: AngularFirestoreCollection<any>;

  user: firebase.User;
  datestamp$: Observable<any>;

  timestamp: any;

  constructor(
    public courseService: SixArtsCoursesService,
    private seo: SeoService,
    public sidebar: SidebarService,
    private auth: LoginService,
    private router: Router
  ) {}

  ngOnInit() {

    this.auth.getUserState()
      .subscribe( user => {
        this.user = user;
      })

    // this.sidebar.closeSidebar();
    this.record$ = this.courseService.getLatestRecord();

    this.course$ = this.courseService
      .getLatestRecord()
      .pipe(switchMap(r => this.courseService.getAllCourses(r[0].id)));

      this.timestamp = this.courseService.getDate();



    this.seo.generateTags({
      title: "六藝學坊",
      description: "六藝學坊",
      // tslint:disable-next-line: max-line-length
      image: "https://firebasestorage.googleapis.com/v0/b/se-ngo.appspot.com/o/images%2Fentrance.jpg?alt=media&token=7f91a6e2-c98e-433e-bb36-7c5892905b36",
      slug: "six-arts"
    });
  }



  checkToggle() {
    this.manage = !this.manage;
    this.delete = false;
    console.log(this.manage);
  }

  checkDelete() {
    this.delete = !this.delete;
    this.manage = false;
    console.log(this.delete);
  }

  login() {
    this.router.navigate(['/login']);

  }

  logout() {
    this.auth.logout();
    this.manage = false;
    this.delete = false;
  }

  updateTime() {
    this.courseService.updateTime();
  }
}
