import { Component, OnInit } from '@angular/core';

import { SidebarService } from '../sidebar.service';

@Component({
  selector: 'app-hi',
  templateUrl: './hi.component.html',
  styleUrls: ['./hi.component.css']
})
export class HiComponent implements OnInit {

  constructor(public sidebar: SidebarService) { }

  ngOnInit() {
  }

}
