import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

import { SeoService } from '../../seo.service';
import { SixArtsCoursesService } from '../six-arts-courses.service';
import { UploadService } from '../upload.service';

@Component({
  selector: 'app-six-arts-course-detail',
  templateUrl: './six-arts-course-detail.component.html',
  styleUrls: ['./six-arts-course-detail.component.css']
})
export class SixArtsCourseDetailComponent implements OnInit, OnDestroy {

  courseGroup: string;
  courseId: string;
  course$: Observable<any>;
  course: any;
  subscription: Subscription;
  colors = ['#ffefd6', '#c0d6f9', '#ffffdb', '#ffdbfb', '#e4f9d4'];
  imagePath: string;

  constructor(
    private route: ActivatedRoute,
    private courseService: SixArtsCoursesService,
    private seo: SeoService,
    private uploadService: UploadService
  ) { }

  ngOnInit() {
    this.courseGroup = this.route.snapshot.paramMap.get('courseGroupId');
    this.courseId = this.route.snapshot.paramMap.get('courseId');
    this.course$ = this.courseService.getCourse(this.courseGroup, this.courseId);
    this.subscription = this.course$.subscribe(c => {
      this.course = c;
    });
    

    this.course$.pipe(take(1)).subscribe(data => {
      this.seo.generateTags({
        title: data.name,
        description: data.內容,
        image: data.imageUrl,
        slug: 'six-arts/' + this.courseGroup + '/' + this.courseId
      });
    });

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
