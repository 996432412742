import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-ideas',
  templateUrl: './project-ideas.component.html',
  styleUrls: ['./project-ideas.component.css']
})
export class ProjectIdeasComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
