import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { SiPastConsultancyService, SIProject } from 'src/app/smart-int/si-past-consultancy.service';

@Component({
  selector: 'app-consultancy',
  templateUrl: './consultancy.component.html',
  styleUrls: ['./consultancy.component.css']
})
export class ConsultancyComponent implements OnInit {

  project$: Observable<SIProject[]>;

  typeFilter = '';

  typeList = [
    'Building Dynamics',
    'Expert Witness',
    'Public Engagement',
    'Railway',
    'Design',
    'R&D',
    'Vibration & Noise',
    'Fire Engineering',
    'Consultancy',
  ];


  constructor(
    private projectServce: SiPastConsultancyService,
  ) { }

  ngOnInit() {
    this.project$ = this.projectServce.getAllRecords();
  }

  onSelectProjectType(buttonInput: string) {
    this.typeFilter = buttonInput;
  }

}
