import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ict2018',
  templateUrl: './ict2018.component.html',
  styleUrls: ['./ict2018.component.css']
})
export class ICT2018Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
