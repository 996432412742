import { Component, OnInit, ViewChild } from '@angular/core';

import { SeoService } from '../seo.service';
import { SidebarService } from '../sidebar.service';
import { app } from 'firebase';
import { MatSidenav } from '@angular/material';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  @ViewChild('sidenav') sidenav: MatSidenav;

  constructor(
    private seo: SeoService,
    public sidebar: SidebarService
  ) { }

  ngOnInit() {

    // this.sidebar.openSidebar();


    this.seo.generateTags({
      title: '社會企業有限公司',
      description: '社會企業有限公司',
      // tslint:disable-next-line:max-line-length
      image: 'https://firebasestorage.googleapis.com/v0/b/se-ngo.appspot.com/o/images%2Fcompany_logo.png?alt=media&token=7e012189-f9f4-4964-9341-38b07dcc892d',
      slug: 'home'
    });

  }

  toggle(){
    this.sidenav.toggle();
  }

}
