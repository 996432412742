import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SixArtsCoursesService, SixArtsCourse, inputForm } from '../../six-arts-courses.service';
import { UploadService } from '../../upload.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
// import { ColorPickerModule } from 'ngx-color-picker';

@Component({
  selector: 'app-six-arts-course-add',
  templateUrl: './six-arts-course-add.component.html',
  styleUrls: ['./six-arts-course-add.component.css']
})


export class SixArtsCourseAddComponent implements OnInit {

  column_names = ["id","日期","時間","內容","導師","學費"]
  column_en = ["id","dayt","timet","description","teacher","tuition"]
  color = "#2889e9";

  data : SixArtsCourse;

  imageUrl : string = "";
  selectedImage: any = null;
  isSubmitted = false;
  // imageUrl : string;

  uploadPercent: Observable<number>;
  downloadURL: Observable<string>;


  constructor(public service : SixArtsCoursesService,
              public upload : UploadService,
              private router: Router,
              private storage: AngularFireStorage
     ){ }

  ngOnInit() {
    this.resetForm();
  }


  showPreview(event:any) {
    if(event.target.files && event.target.files[0]){
      const reader = new FileReader();
      reader.onload = (e:any) => this.imageUrl = e.target.result;
      reader.readAsDataURL(event.target.files[0]);
      this.selectedImage = event.target.files[0];
    }
    else {
      this.imageUrl = '';
      this.selectedImage = null;
    }
  }

  onSubmit(form: NgForm){
    this.isSubmitted = true;


    let pre = form.value;


    // pre.color = this.color;
    // pre.time = pre.time_start + " - " + pre.time_end;
    // pre.imagePath=this.upload.imagePath;
    // delete pre.time_start;
    // delete pre.time_end;

    console.log(pre);

    // // pre.color = parseInt(pre.color);
    pre.column_names = this.column_names;
    pre.column_en = this.column_en;
    // pre.imageUrl = this.imageUrl;

    let data = pre as SixArtsCourse;
    // console.log(data);
    this.upload.uploadFile(this.selectedImage, data);
    // console.log(this.upload.uploadFile(this.selectedImage));

    this.router.navigate(['/six-arts']);


    // var filePath="images/${this.selectedImage.name}";

    // const fileRef = this.storage.ref(filePath);
    // const task = this.storage.upload(filePath, this.selectedImage);

    // task.snapshotChanges().pipe(
    //   finalize(() =>
    //   // console.log("Processing");
    //   fileRef.getDownloadURL().subscribe(url => {
    //     data.imageUrl = url;
    //     this.service.addCourse(data);
    //     console.log(url);
    //   }) )
    // )
    // .subscribe()


    // this.resetForm(form);


  }

  changedColor() {
    console.log(this.color);
  }

  resetForm(form?: NgForm){
    if(form!= null)
      form.resetForm();
    this.service.formData = {
      id : null,
      name : "",
      color:"#ff00ff",
      day: "MON",
      times: "14:30",
      timee: "16:00",
      imageUrl: "",
      description: "",
      teacher: "",
      tuition: "",
      dayt: "",
      timet: ""
    }
    this.imageUrl = "";
    this.selectedImage = null;
    this.isSubmitted = false;
  }


}
