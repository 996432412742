import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-who-are',
  templateUrl: './who-are.component.html',
  styleUrls: ['./who-are.component.css']
})
export class WhoAreComponent implements OnInit {
  @Input() tl;

  constructor() { }

  ngOnInit() {
  }

}
