import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

export interface Cert {
  id?: string;
  column_names: string[];
  data: any[];
  date_upload: any;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class CertRecordService {

  constructor(
    private afs: AngularFirestore,
  ) { }

  getAllRecords() {
    return this.afs.collection<Cert>('cert_records', ref => ref.orderBy('date_upload', 'desc'))
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const id = a.payload.doc.id;
            const data = a.payload.doc.data();
            return {id, ...data};
          });
        })
      );
  }

  get(id: string) {
    return this.afs.collection('cert_records').doc<Cert>(id).valueChanges();
  }
}
