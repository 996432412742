import { Component, OnInit } from '@angular/core';

import { SeoService } from '../seo.service';
import { SidebarService } from '../sidebar.service';

@Component({
  selector: 'app-ehm',
  templateUrl: './ehm.component.html',
  styleUrls: ['./ehm.component.css']
})
export class EhmComponent implements OnInit {

  page: number = 0;

  advantageList = [
    '智保藥物管理系統的中心是個一目了然的七天用藥盒',
    '該每週用藥盒是用特有印上二維數碼的貼紙封住已配好的藥物',
    '藥盒同時貼上印有院友與藥物相片的週表, 防止錯誤',
    '週表上印有所有院友與藥物的資料, 護士不用攜帶大薄派藥',
    '週表另一面可供填寫例外事件, 如H=請假; A=入院; R=拒絕服藥等',
    '配藥及核藥人員可在週表上簽署確認',
    '只須一下按鈕 "電腦週表程式" 就會用本週週表自動產生所有下週週表',
    '藥盒上的二維數碼是用 "數碼程式" 從週表自動產生',
    '派藥用防潮膠箱可妥善安置所有藥盒',
    '派藥員在派藥時可用手機二維數碼掃描器核對院友, 日子與時間. 并由手機即時記錄派藥員, 院友, 日子與時間上載於特別設立的電子郵箱, 以符合社署要求',
    '在特定時段內可即時在電腦上知道漏派那個院友',
    '如有錯誤時, 手機振動以提醒派藥員避免發生藥物事故',
    '核對無錯時, 手機顯示出院友相片及讀出院友名字以供核實. 并可自動記錄例外事項',
    '配有二維掃描器定位儀方便派藥員使用手機掃描',
    '設有"培訓機制"由藥劑師培訓員工使用此智保藥物管理系統'
  ];

  constructor(
    private seo: SeoService,
    public sidebar: SidebarService
  ) { }

  ngOnInit() {
    this.seo.generateTags({
      title: 'EHM 智保網管理系統',
      description: '智保網, 智能院舍, 藥物管理, 電子派藥',
      image: 'https://se-ngo.org/assets/images/laptop_ehm.jpg',
      slug: 'ehm'
    });
  }

  setPage(num) {
    this.page = num;
  }

}
