import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  opened: boolean = false;

  constructor() { }

  closeSidebar(){
    this.opened = false;
  }
  openSidebar(){
    this.opened = true;
  }
}
