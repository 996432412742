import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {DomSanitizer,SafeResourceUrl,} from '@angular/platform-browser';

import { SeoService } from '../../seo.service';
import { SYLLABUS, TrainingCourse } from './syllabus';

@Component({
  selector: 'app-training-syllabus',
  templateUrl: './training-syllabus.component.html',
  styleUrls: ['./training-syllabus.component.css']
})
export class TrainingSyllabusComponent implements OnInit {

  courseId: string;
  syllabusList = SYLLABUS;
  course: TrainingCourse;
  url: SafeResourceUrl;

  constructor(
    private route: ActivatedRoute,
    private seo: SeoService,
    public sanitizer:DomSanitizer
  ) { }

  ngOnInit() {
    this.courseId = this.route.snapshot.paramMap.get('courseId');
    this.course = this.syllabusList[this.courseId];
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.course.link);

    this.seo.generateTags({
      title: '智保康 - ' + this.course.name,
      description: '此' + this.course.name + '將涵蓋以下知識和技巧',
      // tslint:disable-next-line:max-line-length
      image: 'https://firebasestorage.googleapis.com/v0/b/se-ngo.appspot.com/o/images%2Ftraining.jpg?alt=media&token=0d96483d-e5db-4e33-a43e-75207a18f122',
      slug: 'training/' + this.courseId
    });
  }

}
