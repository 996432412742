import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { Cert, CertRecordService } from './cert-record.service';
import { SeoService } from '../seo.service';
import { SidebarService } from '../sidebar.service';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.css']
})
export class TrainingComponent implements OnInit {

  record$: Observable<Cert[]>;

  constructor(
    private recordService: CertRecordService,
    private seo: SeoService,
    public sidebar: SidebarService
  ) { }

  ngOnInit() {

    // this.sidebar.closeSidebar();
    this.record$ = this.recordService.getAllRecords();

    this.seo.generateTags({
      title: '智保康',
      description: '智保康管理教育中心',
      image: 'https://se-ngo.org/assets/images/pcw_logo.png',
      slug: 'training'
    });
  }

}
